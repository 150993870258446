import React, { useEffect } from 'react';
import Input from '../ui/Input';
import Select from '../ui/Select';
import Stack from '../ui/Stack';
import FormField from './FormField';
import FormImageField from './FormImageField';
import Person, { Role } from '../domain/Person';
import useResolver from '../hooks/useResolver';
import { observer } from 'mobx-react';
import DrawerForm from './DrawerForm';
import useQueryValue from '../hooks/useQueryValue';
import PersonSkill from '../domain/PersonSkill';
import ConnectedPersonTagsField from '../containers/ConnectedPersonTagsField';
import { isEmail } from '../util/string';
import PersonStore from '../store/PersonStore';
import OrganizationStore from '../store/OrganizationStore';
import PersonSkillStore from '../store/PersonSkillStore';
import InvitationStore from '../store/InvitationStore';
import Invitation from '../domain/Invitation';
import PercentageInput from '../ui/PercentageInput';
import PriceStore from '../store/PriceStore';
import { useHistory } from 'react-router-dom';
import Checkbox from '../ui/Checkbox';
import {
  ConnectedFormDynamicFields,
  Resources,
} from '../features/dynamic-field';
import { t } from '../i18n';

export interface PersonFormDrawerProps {
  simple?: boolean;
  nextStep?: string;
  organizationId?: string;
  name?: string;
}

const PersonFormDrawer: React.FC<PersonFormDrawerProps> = ({
  simple,
  nextStep,
  organizationId: suppliedOrganizationId,
  name,
}) => {
  const personStore = useResolver(PersonStore);
  const organizationStore = useResolver(OrganizationStore);
  const personSkillStore = useResolver(PersonSkillStore);
  const invitationStore = useResolver(InvitationStore);
  const priceStore = useResolver(PriceStore);
  const personId = useQueryValue(name);
  const history = useHistory();
  const organizationId =
    suppliedOrganizationId || organizationStore.currentOrganizationId;

  const person = {
    role: Role.USER,
    ...(personId ? personStore.getById(personId) : {}),
  };

  const invitation =
    person.id && invitationStore.getLatestPersonInvitation(person.id);

  useEffect(() => {
    if (personId) {
      priceStore.loadByOrganizationAndExternalId(organizationId, personId);
    }
  }, [personId]);

  const handleSubmit = async (
    {
      tags,
      sendInvitation,
      ...values
    }: Person & {
      sendInvitation?: boolean;
      tags?: any;
    },
    personId: string,
  ) => {
    let person: Person;

    if (personId) {
      person = await personStore.update({ id: personId, ...values });
    } else {
      person = await personStore.create({
        organizationId,
        ...values,
      });
    }

    if (sendInvitation && values.contactEmail) {
      await invitationStore.create(
        new Invitation().fromObject({
          email: values.contactEmail,
          personId: person.id,
        }),
      );
    }

    if (tags) {
      personSkillStore.syncPersonSkills(
        person.id,
        Object.values(tags)
          .flat()
          .filter(Boolean)
          .map((tag: any) => {
            const personSkill = new PersonSkill();
            personSkill.tagId = tag.value;

            return personSkill;
          }),
      );
    }

    history.push(nextStep || `/profile/view/${person.id}`);
  };

  return (
    <DrawerForm
      name={name}
      onSubmit={handleSubmit}
      header={<>{personId ? t('Edit profile') : t('Add profile')}</>}
      defaultValues={{
        ...person,
        contactEmail: person?.contactEmail || person?.userId,
      }}
    >
      {({ formContext }) => (
        <Stack spacing={4}>
          <FormField name="name" label={t('Name')} required>
            <Input autoFocus={!personId} />
          </FormField>

          <FormField
            name="contactEmail"
            label={t('Email')}
            validate={(value) =>
              value ? isEmail(value) || t('Invalid email') : true
            }
          >
            <Input />
          </FormField>

          {!simple &&
            !person?.userId &&
            !invitation &&
            !!formContext.watch('contactEmail') && (
              <FormField name="sendInvitation">
                <Checkbox>{t('Send invitation link to email?')}</Checkbox>
              </FormField>
            )}

          {!simple &&
            !!person?.userId &&
            !invitation &&
            !!formContext.watch('contactEmail') &&
            formContext.watch('contactEmail') !== person?.userId && (
              <FormField name="sendInvitation">
                <Checkbox>
                  {t('Use this email to sign in? New invitation will be sent')}
                </Checkbox>
              </FormField>
            )}

          <FormField name="position" label={t('Position')}>
            <Input />
          </FormField>

          {!simple && (
            <FormField name="role" label={t('Access')}>
              <Select>
                <option value={Role.ADMIN}>{t('Admin')}</option>
                <option value={Role.MANAGER}>{t('Manager')}</option>
                <option value={Role.USER}>{t('User')}</option>
                {person.role === Role.DISABLED && (
                  <option value={Role.DISABLED}>{t('Disabled')}</option>
                )}
              </Select>
            </FormField>
          )}

          <FormField name="capacity" label={t('Capacity')} defaultValue={1}>
            <PercentageInput />
          </FormField>

          <ConnectedPersonTagsField
            personId={personId}
            suppliedOrganizationId={organizationId}
          />

          <FormImageField label={t('Image')} name="image" />

          <FormField name="contactPhone" label={t('Phone')}>
            <Input />
          </FormField>

          <ConnectedFormDynamicFields
            tags={[Resources.PERSONS]}
            formContext={formContext}
          />
        </Stack>
      )}
    </DrawerForm>
  );
};

PersonFormDrawer.defaultProps = {
  name: 'person',
};

export default observer(PersonFormDrawer);
